<template>
  <div class="content">
    Collaboration tasks
  </div>
</template>

<script>
export default {
  components: {
  }

}
</script>

<style lang="stylus" scoped>
.content
  align-items stretch
  display flex
  flex-direction column
  flex-grow 1
  flex-shrink 1
  overflow auto
  padding 0.5em
</style>
